<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="600"
    >
    <v-card>
      <v-card-title>
        Seguidores
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="back"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <list
          :results="results"
          ></list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import List from './List'

import { Followers } from '@/graphql/queries/profiles'

export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      results: [],
      dialog: false
    }
  },

  created () {
    this.fetchFollowers()

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (!to.params.search) {
        this.back()

        next(false)
      } else {
        next()
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },

  methods: {
    fetchFollowers () {
      this.$apollo.query({
        query: Followers,
        variables: {
          slug: this.slug
        }
      }).then ( res => {
        this.results = res.data.followers
      })
    },

    back () {
      this.dialog = false
      this.$emit('close')
    }
  },

  components: { List }
}
</script>
